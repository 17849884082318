import http from './http'

export function bookingRequiresAuthorisation (bookingToken) {
  return http.get(`/book/${bookingToken}/authorisation`)
}

export function setApprovalAuthoriser (bookingToken, authoriserEmail) {
  return http.post(`/book/${bookingToken}/authorisation`, { authoriser: authoriserEmail })
}

export function account (token, params) {
  return http.post(`/book/${token}`, { payment_method: 'account', ...params })
}

export function paypal (token, params) {
  return http.post(`book/${token}`, { payment_method: 'paypal_order', ...params })
}

export function creditcard (token, params) {
  return http.post(`book/${token}`, params)
}

export function preauthed (token, params) {
  return http.post(`book/${token}`, params)
}

export function supplier_handled (token, params) {
  return http.post(`book/${token}`, { payment_method: 'supplier_handled', ...params })
}
