<template>
  <q-page padding class="column content-center">
    <span class="text-h6 text-center q-mb-sm">
      Sorry, the booking request has expired, please select an option below
    </span>
    <m-cta-button label="Reattempt Booking" @ctaClick="reattemptBooking" />
    <m-cta-button outlined label="Start New Search" @ctaClick="startNewSearch" />
  </q-page>
</template>
<script>
import { handleErrors } from 'utils/utils'
import { MCtaButton } from 'components'

export default {
  components: { MCtaButton },
  methods: {
    reattemptBooking () {
      this.$router.push({ name: 'ondemand-onwardtravel-results' })
      this.$store.dispatch('onwardtravel/search')
        .catch((err) => {
          handleErrors(err)
        })
    },
    startNewSearch () {
      this.$router.push({ name: 'ondemand-onwardtravel' })
    }
  }
}
</script>
