<template>
  <div v-if="routeInfoLoading" class="col-6 relative-position column justify loading">
    <div class="row">
      <div class="col column justify-between">
        <q-item-label>
          Distance
        </q-item-label>
        <q-item-label caption>
          <q-skeleton animation="pulse" width="75%" type="text" />
        </q-item-label>
      </div>
      <div class="col-8 text-right column justify-between">
        <q-item-label>
          Estimated mileage
        </q-item-label>
        <q-item-label caption>
          <q-skeleton animation="pulse" width="75%" type="text" class="float-right" />
        </q-item-label>
      </div>
    </div>
  </div>
  <div v-else-if="routeInfo && !routeInfoLoading && !routeInfo.message" class="col-6 relative-position column justify">
    <div class="row">
      <div class="col column justify-between">
        <q-item-label>
          Distance
        </q-item-label>
        <q-item-label caption>
          <strong>{{ Number(routeInfo.distance.miles).toFixed(2) }}</strong> miles
        </q-item-label>
      </div>
      <div class="col-8 text-right column justify-between">
        <q-item-label>
          Estimated duration
        </q-item-label>
        <q-item-label caption>
          <strong>{{ routeInfo.duration.text.split(" ")[0] }}</strong> {{ durationFormat }}
        </q-item-label>
      </div>
    </div>
  </div>
  <div v-else-if="routeInfo && routeInfo.message" class="col-6 relative-position column justify-center text-center">
    <div class="text-subtitle2">
      {{ routeInfo.message }}
    </div>
  </div>
  <div v-else class="col-6 relative-position column justify-center text-center">
    <div class="text-subtitle2">
      Enter a start and end location, to get an estimate mileage and duration.
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: ['routeInfo'],
  computed: {
    ...mapGetters({
      routeInfoLoading: 'onwardtravel/routeInfoLoading'
    }),
    durationFormat () {
      if (!this.routeInfo) return
      const split = this.routeInfo.duration.text.split(' ')
      split.shift()
      return split.join(' ').trim()
    }
  }
}
</script>
<style lang="stylus" scoped>
  .loading
    opacity 0.25
</style>
