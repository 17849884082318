<template>
  <q-page class="page bg-white column items-center no-wrap">
    <div class="code" :class="$store.getters.partner.slug" />
    <div class="container">
      <div class="row">
        <q-card
          square
          class="bg-white search-form"
        >
          <q-card-section class="bg-primary text-white text-uppercase">
            {{ $tc('find.onward_travel') }}
          </q-card-section>
          <q-separator />
          <q-card-section class="column">
            <div class="row q-col-gutter-sm">
              <div class="col-6 relative-position">
                <m-location-search
                  :value="origin"
                  :label="$t('pick_up_location')"
                  :error="!!originError"
                  :error-message="originError"
                  @selected="setOrigin"
                />
              </div>
              <div class="col-6 relative-position">
                <m-location-search
                  :value="destination"
                  :label="$t('drop_off_location')"
                  :error="!!destinationError"
                  :error-message="destinationError"
                  @selected="setDestination"
                />
              </div>
              <div class="col-6 relative-position">
                <m-date-field :value="time" :min="minPickup" :label="$t('date.pick_up')" @change="setTime" />
              </div>
              <div class="col-6 relative-position">
                <m-time-field :value="time" :min="minPickup" :label="$t('time.pick_up')" @change="setTime" />
              </div>
              <div class="col-6 relative-position">
                <q-input
                  :value="numberOfPassengers"
                  :min="1"
                  :max="30"
                  type="number"
                  :label="$t('number_of.passengers')"
                  @input="setPassengers"
                />
              </div>
              <estimates :route-info="routeInfo" />
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="row">
        <div class="col">
          <m-unified-users v-model="people" hide-traveller />
        </div>
      </div>
      <div class="cta">
        <m-cta-button label="Find available vehicle" :disabled="disabled" @ctaClick="getResults" />
      </div>
    </div>
  </q-page>
</template>
<script>
import 'styles/ondemand.styl'
import date from 'utils/date-time'
import { MTimeField, MDateField, MLocationSearch, MUnifiedUsers, MCtaButton } from 'components/'
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import authentication from 'mixins/authentication'
import estimates from './estimates'
import { handleErrors } from 'utils/utils'
const { roundMinutesUp, addToDate } = date

export default {
  components: {
    estimates,
    MTimeField,
    MDateField,
    MLocationSearch,
    MUnifiedUsers,
    MCtaButton
  },
  mixins: [authentication],
  data () {
    return {
      originError: null,
      destinationError: null
    }
  },
  computed: {
    ...mapGetters({
      origin: 'onwardtravel/origin',
      destination: 'onwardtravel/destination',
      time: 'onwardtravel/time',
      requester: 'onwardtravel/requester',
      numberOfPassengers: 'onwardtravel/numberOfPassengers',
      routeInfo: 'onwardtravel/routeInfo',
      userLookup: 'userLookup'
    }),
    minPickup () {
      return roundMinutesUp(addToDate(date.newDate(), { minutes: 20 }), 5)
    },
    disabled () {
      this.$v.$touch()
      return this.$v.$error
    },
    people: {
      get () {
        return {
          traveller: this.requester,
          requester: this.requester
        }
      },
      set (val) {
        this.$store.dispatch('onwardtravel/setRequester', val.requester)
      }
    }
  },
  created () {
    this.$store.dispatch('onwardtravel/reset')
    if (this.hasRole('middleoffice')) this.setRequester()
    this.setTime(this.minPickup)
  },
  methods: {
    async setOrigin (val) {
      await this.$store.dispatch('onwardtravel/setLocation', { field: 'origin', location: val })
      if (!this.origin) {
        this.originError = this.$t('error.onward_travel.location_not_found')
      }
    },
    async setDestination (val) {
      await this.$store.dispatch('onwardtravel/setLocation', { field: 'destination', location: val })
      if (!this.destination) {
        this.destinationError = this.$t('error.onward_travel.location_not_found')
      }
    },
    setRequester () {
      this.$store.dispatch('onwardtravel/setRequester', this.userLookup)
    },
    setTime (val) {
      // Check the new value against minPickupTime
      const { values: { minutes } } = date.getDateDiff(val, this.minPickup, 'minutes')

      if (minutes < 0) {
        this.$q.notify({
          color: 'negative',
          message: 'The departure time must be at least 20 minutes from now'
        })
      } else {
        this.$store.dispatch('onwardtravel/setValue', { prop: 'time', payload: val })
      }
    },
    setPassengers (val) {
      this.$store.dispatch('onwardtravel/setValue', { prop: 'numberOfPassengers', payload: parseInt(val) })
    },
    getResults () {
      this.$v.$touch()
      if (!this.$v.$error) {
        this.$router.push({ name: 'ondemand-onwardtravel-results' })
        this.$store.dispatch('onwardtravel/search')
          .catch((err) => {
            handleErrors(err)
          })
      } else {
        this.$q.notify({ color: 'negative', icon: 'clear', message: this.$t('taxi.validation.form') })
      }
    }
  },
  validations: {
    origin: { required },
    destination: { required },
    time: { required },
    requester: { required },
    numberOfPassengers: { required }
  }
}
</script>
<style lang="stylus" scoped>

.search-form
  position relative

.column.container
  margin-top -64px

.q-card
  width 80vw
  max-width 600px
</style>
