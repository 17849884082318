<template>
  <q-page padding>
    <div v-if="summary" class="row q-col-gutter-xs">
      <div class="col-xs-12">
        <q-card class="bg-white">
          <q-card-section :class="`bg-primary text-white`">
            {{ $t('booking.summary') }}
          </q-card-section>
          <q-card-section>
            <div
              class="row"
              style="margin-top: 16px"
            >
              <div class="col-4">
                <sub>{{ $t('vehicle_type') }}</sub>
                <h6 class="text-grey-9">
                  {{ summary.ride.vehicle.type }} {{ summary.ride.taxi_company === "Uber" ? "- Uber" : "" }}
                  <br>
                  {{ summary.ride.title }}
                </h6>
              </div>
              <div class="col-4">
                <sub>{{ $t('description') }}</sub>
                <h6 class="text-grey-9">
                  {{ summary.ride.description }}
                </h6>
              </div>
              <div class="col-3">
                <m-image :src="summary.ride.vehicle.images[0]" :fallback="fallback" width="142" height="88" />
              </div>
            </div>

            <div
              class="row q-col-gutter-sm"
              style="margin-top: 16px"
            >
              <div class="col-6">
                <sub>{{ $t('pick_up') }}</sub>
                <h6 class="text-grey-9">
                  {{ summary.pickup_time.date | date }}
                </h6>
                {{ summary.origin }}
              </div>
              <div class="col-6">
                <sub>{{ $t('drop_off') }}</sub>
                <h6 class="text-grey-9" />
                {{ summary.destination }}
              </div>
            </div>
            <q-separator class="hr" />
            <h6>{{ $t('other_travel_information') }}</h6>
            <div class="grid">
              <q-item v-if="summary.passenger_name">
                <q-item-section>
                  <q-item-label label>
                    {{ $t('passenger_name') }}
                  </q-item-label>
                  <q-item-label caption>
                    {{ summary.passenger_name }}
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item v-if="summary.phone_number">
                <q-item-section>
                  <q-item-label label>
                    {{ $t('phone_number') }}
                  </q-item-label>
                  <q-item-label caption>
                    {{ summary.phone_number }}
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item v-if="summary.internal_notes">
                <q-item-section>
                  <q-item-label label>
                    {{ $t('rac.interal_notes') }}
                  </q-item-label>
                  <q-item-label caption>
                    {{ summary.internal_notes }}
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item v-if="summary.instructions">
                <q-item-section>
                  <q-item-label label>
                    {{ $t('instructions') }}
                  </q-item-label>
                  <q-item-label caption>
                    {{ summary.instructions }}
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item v-if="summary.internal_notes">
                <q-item-section>
                  <q-item-label label>
                    {{ $t('rac.internal_notes') }}
                  </q-item-label>
                  <q-item-label caption>
                    {{ summary.internal_notes }}
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item v-if="summary.cost_centre">
                <q-item-section>
                  <q-item-label label>
                    {{ $t('rac.cost_centre') }}
                  </q-item-label>
                  <q-item-label caption>
                    {{ summary.cost_centre }}
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item v-if="summary.number_of_passengers">
                <q-item-section>
                  <q-item-label label>
                    {{ $t('number_of.passengers') }}
                  </q-item-label>
                  <q-item-label caption>
                    {{ summary.number_of_passengers }}
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item v-if="summary.customer_reference">
                <q-item-section>
                  <q-item-label label>
                    {{ $t('onwardtravel.customer_reference') }}
                  </q-item-label>
                  <q-item-label caption>
                    {{ summary.customer_reference }}
                  </q-item-label>
                </q-item-section>
              </q-item>
            </div>
            <q-separator class="hr" />
            <div v-for="(item, i) in summary.order_items" :key="i" class="order-item">
              <div class="description">
                {{ item.description }}
              </div>
              <div class="grid">
                <q-item>
                  <q-item-section>
                    <q-item-label label>
                      {{ $t('net_amount') }}
                    </q-item-label>
                    <q-item-label caption>
                      {{ item.net_amount.text }}
                    </q-item-label>
                  </q-item-section>
                </q-item>
                <q-item>
                  <q-item-section>
                    <q-item-label label>
                      {{ $t('tax.amount') }}
                    </q-item-label>
                    <q-item-label caption>
                      {{ item.tax_amount.text }}
                    </q-item-label>
                  </q-item-section>
                </q-item>
                <q-item>
                  <q-item-section>
                    <q-item-label label>
                      {{ $t('total_cost') }}
                    </q-item-label>
                    <q-item-label caption>
                      {{ item.total.text }}
                    </q-item-label>
                  </q-item-section>
                </q-item>
              </div>
            </div>

            <q-separator class="hr" />
            <h6 class="row text-bold">
              <span class="offset-5 col-4 text-grey-9 text-right">{{ $t('total_cost') }}</span>
              <span class="col-3 text-grey-9 text-right">{{ summary.total_amount.text }}</span>
            </h6>

            <q-separator class="hr" />
            <div class="order-item">
              <div class="description">
                {{ $t('onwardtravel.cost_per_mile.summary') }}
              </div>
              <div v-if="costPerMilePolicy" class="row justify-between">
                <q-item>
                  <q-item-section>
                    <q-item-label caption>
                      {{ $t('onwardtravel.cost_per_mile.policy') }}
                    </q-item-label>
                  </q-item-section>
                </q-item>
                <q-item>
                  <q-item-section>
                    <q-item-label caption>
                      £{{ Number(costPerMilePolicy / 100).toFixed(2) }}
                    </q-item-label>
                  </q-item-section>
                </q-item>
              </div>
              <div class="row justify-between">
                <q-item>
                  <q-item-section>
                    <q-item-label caption>
                      {{ $t('onwardtravel.cost_per_mile.booking') }}
                    </q-item-label>
                  </q-item-section>
                </q-item>
                <q-item>
                  <q-item-section>
                    <q-item-label caption>
                      {{ selectedVehicle.cost_per_mile.text }}
                    </q-item-label>
                  </q-item-section>
                </q-item>
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
      <policy-warning-card v-if="showPolicyWarningCard" @click="$router.push( { hash: '/concierge/onwardtravel' } )" />
      <concierge-card v-else :title="$t('need_assistance')" @click="$router.push( { hash: '/concierge/onwardtravel' } )" />
      <div class="col-xs-12">
        <m-attach-to-booking-request
          :show-travel-type="false"
          :suggested="summary.suggested_name"
          :journey="journey"
          @change="setJourney"
          @reasonChange="(val) => reason_for = val"
          @labelChange="(val) => labels = val"
        />
      </div>
      <expired v-if="bookingHasExpired" />
      <payment-buttons
        v-if="!bookingHasExpired"
        :total-amount="summary.total_amount.amount"
        :requester="requester"
        :traveller="traveller || requester"
        :journey="journey"
        :reason-for="reason_for"
        :labels="labels"
        :booking-token="summary.booking_token"
        content-type="onwardtravel"
      />
      <m-terms />
    </div>
  </q-page>
</template>

<script type="text/javascript">
import policyWarningCard from './policy-warning-card'
import ConciergeCard from './concierge-card.vue'
import { mapGetters } from 'vuex'
import authentication from 'mixins/authentication'
import date from 'utils/date-time'
import paymentButtons from 'pages/payments/payment-method-buttons'
import { MImage, MAttachToBookingRequest, MTerms } from 'components/'
import expired from './expired.vue'

export default {
  name: 'OndemandonwardtravelSummary',
  components: {
    paymentButtons,
    policyWarningCard,
    ConciergeCard,
    MImage,
    MAttachToBookingRequest,
    MTerms,
    expired
  },
  filters: {
    date: value => date.toMediumDateTime(value)
  },
  mixins: [authentication],
  data () {
    return {
      reason_for: null,
      labels: null,
      fallback: require('assets/blank-vehicle-hire.png')
    }
  },
  computed: {
    ...mapGetters({
      summary: 'onwardtravel/summary',
      traveller: 'onwardtravel/traveller',
      requester: 'onwardtravel/requester',
      selectedVehicle: 'onwardtravel/selectedVehicle',
      journey: 'onwardtravel/journey',
      partner: 'partner',
      timerExpiration: 'onwardtravel/timerExpiration'
    }),
    costPerMilePolicy () {
      const org = this.$store.getters.authentication.organisation
      if (!org) return false
      const policy = org.attributes.transport_policy ? org.attributes.transport_policy.onwardtravel : false
      return policy ? policy.budget : false
    },
    showPolicyWarningCard () {
      const policy = this.costPerMilePolicy
      const thisBooking = this.selectedVehicle.cost_per_mile.amount
      if (!policy) return false
      return policy < thisBooking || false
    },
    bookingHasExpired () {
      return new Date().getTime() > this.timerExpiration
    }
  },
  created () {
    this.$store.dispatch('onwardtravel/setJourney', { name: this.summary.suggested_name, reference: null })
  },
  methods: {
    setJourney (val) {
      this.$store.dispatch('onwardtravel/setJourney', val)
    }
  }
}
</script>

<style lang="stylus" scoped>
.layout-padding
  max-width 768px!important
  margin auto
span, .hr
  margin-top 16px
button
  width 100%
.summary-main
  padding 20px

.grid
  display grid
  grid-template-columns repeat(auto-fill, 33%);
  font-weight 400
  align-items baseline

.order-item
  padding 10px 0

.grid > div
  padding 10px 0

.grid > div:nth-child(2)
  text-align center

.grid > div:nth-child(3)
  text-align right

.description
  font-weight 700

h6.text-bold
  padding 0 10px
</style>
