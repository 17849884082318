<template>
  <div class="vehicle-card col-12 col-xl-6">
    <q-card>
      <q-item multiline class="vehicle" @click.native="goToDetail">
        <q-item-section side class="img-container">
          <m-image
            :src="vehicle.vehicle.images[0]"
            :fallback="fallback"
            :style="'max-width: 96px; max-height: 96px; z-index: 200'"
          />
        </q-item-section>
        <q-item-section class="relative-position">
          <q-item-label label>
            {{ vehicle.title }} {{ hasRole('backoffice') ? `(${vehicle.provider})` : '' }} {{ vehicle.taxi_company === "Uber" ? "- Uber" : '' }}
          </q-item-label>
          <q-item-label caption>
            {{ vehicle.description }}
          </q-item-label>
        </q-item-section>
        <q-item-section side>
          <q-btn :label="priceText" outline class="btn-price full-width" />
          <div v-if="vehicle.vehicle.passenger_capacity || vehicle.vehicle.luggage_capacity" class="row q-gutter-md justify-between q-pa-xs q-pt-sm">
            <strong v-if="vehicle.vehicle.passenger_capacity">
              <q-icon size="xs" name="fas fa-users" /> {{ vehicle.vehicle.passenger_capacity }}
            </strong>
            <strong v-if="vehicle.vehicle.luggage_capacity">
              <q-icon size="xs" name="fas fa-suitcase" /> {{ vehicle.vehicle.luggage_capacity }}
            </strong>
          </div>
        </q-item-section>
      </q-item>
    </q-card>

    <q-dialog
      v-model="wavDialog"
      persistent
    >
      <q-card>
        <q-card-section class="bg-primary">
          Wheelchair Accessible Vehicle
        </q-card-section>
        <q-card-section>
          The vehicle you have selected is designed ONLY for wheelchair users and other passengers who require mobility assistance.
          <br><br>
          This vehicle is NOT FOR USE by passengers who do not require mobility assistance.
          <br><br>
          If the passenger requires mobility assistance, please press OK. Otherwise, press CANCEL and choose an appropriate vehicle.
        </q-card-section>
        <q-card-actions align="between">
          <q-btn v-close-popup label="OK" color="primary" @click.native="goToDetail" />
          <q-btn v-close-popup label="Cancel" color="primary" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { priceText as priceTextUtil } from 'utils/onwardTravel'
import { MImage } from 'components/'
import authentication from 'mixins/authentication'

export default {
  components: { MImage },
  mixins: [authentication],
  props: ['vehicle'],
  data () {
    return {
      fallback: require('assets/blank-vehicle-hire.png'),
      wavDialog: false
    }
  },
  computed: {
    priceText () {
      return priceTextUtil(this.vehicle)
    }
  },
  methods: {
    goToDetail () {
      if (this.vehicle.vehicle.type === 'WAV' && !this.wavDialog) {
        this.wavDialog = true
      } else {
        this.$store.dispatch('onwardtravel/setValue', { prop: 'selectedVehicle', payload: this.vehicle })
        this.$router.push({ name: 'ondemand-onwardtravel-details' })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.vehicle-card
  z-index 100
  position relative

.detail
  font-size 14px

.vehicle
  cursor pointer

.btn-price
  color #1e88e5!important
  border-color #1e88e5!important

.vehicle:hover .btn-price
  background #1e88e5!important
  border-color #1e88e5!important
  color white!important

.q-item
  min-height 100px

.q-item__label
  z-index 200
  text-shadow 0px 0px 2px #FFFFFF

</style>
