<template>
  <div
    v-if="
      !hasRole('guest') &&
        (!bookingNeedsApproval || permissionDerivedTravelType === 'Private')
    "
    class="payment-buttons col-xs-12"
  >
    <!-- Standard payment flow -->
    <div class="row q-col-gutter-sm">
      <div v-if="requesterMustPayBySupplier" class="col-xs-12">
        <m-cta-button :disabled="disabled" @ctaClick="payBy('supplier')">
          {{ $t('complete_booking') }}
        </m-cta-button>
      </div>
      <div v-if="requesterCanPayByCard" class="col-xs-12">
        <m-cta-button :disabled="disabled" @ctaClick="payBy('card')">
          {{ $t('pay_by.card') }}
        </m-cta-button>
      </div>
      <div
        v-if="
          requesterCanPayByAccount && permissionDerivedTravelType === 'Business'
        "
        class="col-xs-12"
      >
        <m-cta-button
          outlined
          :disabled="disabled"
          @ctaClick="payBy('account')"
        >
          {{ $t('pay_by.account') }}
        </m-cta-button>
      </div>
      <div
        v-if="requesterCanPayByPaypal"
        class="col-xs-12 q-mx-xs"
        data-testid="m-paypal"
      >
        <m-paypal
          v-if="!disabled"
          :pence="totalAmount"
          @approved="paypalApproved"
        />
      </div>
    </div>
  </div>
  <div v-else-if="bookingNeedsApproval" class="payment-buttons col-xs-12">
    <!-- Booking requires approval of an authoriser -->
    <div class="row q-col-gutter-sm">
      <div class="col-xs-12">
        <q-btn-dropdown
          split
          no-caps
          color="primary"
          class="full-width"
          @click="payBy('authoriserApproval')"
        >
          <template v-slot:label>
            <q-item-section v-if="requestAuthoriser">
              <q-item-label class="text-uppercase text-h6">
                {{
                  $t('booking_authorisation.request_approval', {
                    authoriser:
                      requestAuthoriser.name ||
                      $t('booking_authorisation.authoriser'),
                  })
                }}
              </q-item-label>
              <q-item-label class="text-subtitle2">
                {{ requestAuthoriser.email }}
              </q-item-label>
            </q-item-section>
            <q-item-section v-else>
              <q-item-label class="text-uppercase text-h6">
                {{ $t('booking_authorisation.select_authoriser') }}
              </q-item-label>
            </q-item-section>
          </template>
          <q-list>
            <q-item
              v-for="(authoriser, index) in availableAuthorisers"
              :key="index"
              v-close-popup
              clickable
              @click="setAuthoriser(authoriser, index)"
            >
              <q-item-section>
                <q-item-label>
                  {{
                    authoriser.name ||
                      $t(`booking_authorisation.authoriser_${index + 1}`)
                  }}
                </q-item-label>
                <q-item-label caption>
                  {{ authoriser.email }}
                </q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </div>
    </div>
  </div>
  <!-- User is a guest, show blocker for guests -->
  <m-guest-blocker v-else />
</template>

<script>
import { MPaypal, MGuestBlocker, MCtaButton } from 'components/index'
import authentication from 'mixins/authentication'
import loading from 'utils/loading'
import { mapGetters } from 'vuex'
import { setApprovalAuthoriser, bookingRequiresAuthorisation } from 'api/book'
import { handleErrors } from 'utils/utils'
export default {
  components: { MPaypal, MGuestBlocker, MCtaButton },
  mixins: [authentication],
  props: {
    totalAmount: Number,
    requester: Object,
    traveller: Object,
    bookingToken: String,
    reasonFor: String,
    journey: Object,
    labels: Array,
    supplierHandled: Boolean,
    travelType: String,
    isBikeReturn: Boolean,
    acceptance: Object,
    disable: Boolean
  },
  data () {
    return {
      requestAuthoriser: null,
      bookingNeedsApproval: false,
      availableAuthorisers: []
    }
  },
  computed: {
    ...mapGetters({
      partner: 'partner'
    }),
    permissionDerivedTravelType () {
      return this.requester.permissions.includes('payments.account') ? this.travelType ? this.travelType : 'Business' : 'Private'
    },
    requesterCanPayByAccount () {
      if (this.supplierHandled) {
        return false
      }
      return this.requester.permissions.includes('payments.account')
    },
    requesterCanPayByCard () {
      if (this.supplierHandled) {
        return false
      }
      return this.requester.permissions.includes('payments.card')
    },
    requesterCanPayByPaypal () {
      if (this.supplierHandled) {
        return false
      }
      return !this.hasRole('backoffice') && this.requester.permissions.includes('payments.paypal')
    },
    requesterMustPayBySupplier () {
      if (this.supplierHandled) {
        return true
      }
      return false
    },
    paymentParameters () {
      return {
        token: this.bookingToken,
        requester: this.requester,
        journey: this.journey,
        reasonFor: this.reasonFor,
        labels: this.labels,
        travelType: this.travelType,
        isBikeReturn: this.isBikeReturn,
        acceptance: this.acceptance,
        isBookingAuthorisation: this.bookingNeedsApproval && this.permissionDerivedTravelType !== 'Private',
        authoriser: this.requestAuthoriser?.email
      }
    },
    disabled () {
      if (this.disable) return true
      if (this.journey.name || this.journey.reference) return false
      return true
    }
  },
  created () {
    bookingRequiresAuthorisation(this.bookingToken)
      .then(({ data: { authorisation_required, first_authoriser, second_authoriser } }) => {
        this.bookingNeedsApproval = authorisation_required

        this.availableAuthorisers = [
          { email: first_authoriser },
          { email: second_authoriser }
        ]
      })
      .catch(err => {
        // TODO: Remove this workaround once this endpoint exists in production
        if (err.data.title === 'NotFoundHttpException') return
        handleErrors(err)
      })
  },
  methods: {
    payBy (paymentMethod) {
      if (paymentMethod === 'authoriserApproval' && !this.requestAuthoriser) {
        this.$q.notify({
          type: 'negative',
          message: this.$t('booking_authorisation.select_authoriser')
        })
        return
      }

      loading.start({
        message: this.$t('loading.preparing.payment_method'),
        partnerSlug: this.partner.slug,
        spinnerColor: this.partner.hex
      })

      this.$store.dispatch('payment/setParams', this.paymentParameters)

      if (paymentMethod === 'authoriserApproval') {
        setApprovalAuthoriser(this.bookingToken, this.requestAuthoriser?.email)
          .then(() => {
            // Only upon succesfully setting the authoriser, do we continue the usual flow of using pay on account
            this.$router.push({ name: 'booking-payment-account', params: this.paymentParameters })
          })
          .catch(err => {
            handleErrors(err)
          })
          .finally(() => {
            loading.stop() // stop loading regardless of success or failure
          })
      } else {
        this.$router.push({ name: `booking-payment-${paymentMethod}`, params: this.paymentParameters })
      }
    },
    paypalApproved (data) {
      this.$store.dispatch('payment/setParams', this.paymentParameters)

      this.$router.push({
        name: 'booking-payment-paypal',
        params: {
          ...this.paymentParameters,
          data
        }
      })
    },
    setAuthoriser (authoriser, index) {
      index = index + 1
      this.requestAuthoriser = { ...authoriser, index }
    }
  }
}
</script>

<style lang="stylus" scoped>
.payment-buttons
  padding 16px
  margin 0 -6px

button
  width 100%
</style>
