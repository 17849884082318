<template>
  <div class="col-12 cursor-pointer" @click="$emit('click')">
    <q-banner ref="banner" dense rounded class="bg-primary q-mx-xs q-mt-md q-mb-sm q-pa-md">
      <template v-slot:avatar>
        <q-icon name="fas fa-concierge-bell" size="32px" class="q-mr-sm" />
      </template>

      <div class="text-subtitl2">
        {{ title || $t('cannot_find') }}
      </div>
      <div class="text-bold">
        {{ subtitle || $t('onwardtravel.concierge_button') }}
      </div>
    </q-banner>
  </div>
</template>
<script>
export default {
  props: ['title', 'subtitle']
}
</script>
